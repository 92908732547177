import React from 'react';
import { SingleCaseStudy } from 'views/single-case-study';
import { useAirdance } from 'views/single-case-study/hooks/useAirdance';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/case-studies/airdance.json';
import caseStudiesMessages from 'constants/translations/case-studies/index.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const AirdancePage = (props) => {
    const { location } = props;
    const { data } = useAirdance();

    return (
        <Layout
            location={location}
            messages={{ ...messages, ...caseStudiesMessages }}
        >
            <SingleCaseStudy data={data} />
        </Layout>
    );
};

AirdancePage.propTypes = {
    location: object.isRequired,
};

export default AirdancePage;

export const Head = () => <SEO tags={TAGS.CASE_STUDIES_AIRDANCE} />;
